<template>
  <div :class="`data-grid-container${isMobile ? ' mobile' : ''}`">
    <v-progress-linear
      class="loader"
      indeterminate
      v-show="isLoading"
      color="loaderColor"
    />

    <div
      :class="`data-grid${isLoading ? ' is-loading' : ''}${
        isMobile ? ' mobile' : ''
      }`"
      v-resize="onResize"
    >
      <slot name="header-buttons"></slot>

      <div class="filter-container">
        <slot name="header" class="filter-options"></slot>
        <v-text-field
          v-if="!isMobile"
          class="text-searching"
          clearable
          v-model="tmpSearch"
          prepend-icon="stem-search"
          :label="$t('common.search')"
          single-line
          hide-details
          color="fields"
          @keyup.enter="searchHandler"
          @click:clear="
            tmpSearch = '';
            searchHandler();
          "
          @click:append="searchHandler"
        />
      </div>

      <div class="content-header">
        <slot name="toolbar"></slot>

        <div class="header-actions">
          <slot name="toolbar-actions"></slot>

          <v-menu v-if="!isMobile" top offset-y open-on-hover>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-if="showGear" large icon text v-bind="attrs" v-on="on">
                <IconBox color="iconButton">stem-viewMode</IconBox>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                @click="$emit('update:display', displayListTypes.CARDS)"
              >
                <v-list-item-title>
                  <IconBox color="iconButton">stem-tiles</IconBox>
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="allowDisplayListMode"
                @click="$emit('update:display', displayListTypes.INLINE)"
              >
                <v-list-item-title>
                  <IconBox color="iconButton" :styles="{ 'font-size': '20px' }">
                    stem-list
                  </IconBox>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>

      <div class="pagination">
        <v-data-footer
          v-if="!isLoading && pagination.pageCount > 1"
          :pagination="pagination"
          :options="pagination"
          :itemsPerPageOptions="rowsPerPageItems"
          @update:options="paginationHandler"
          showFirstLastPage
          showCurrentPage
        >
          <template slot="page-text">
            {{ displayPagePagination() }}
          </template>
        </v-data-footer>
      </div>

      <v-data-iterator
        :class="`content ${display} ${items.length === 0 ? 'empty' : ''}`"
        :items="items"
        v-resize="onResize"
        ref="table"
        :loading="isLoading"
        hideDefaultFooter
        :itemsPerPage="this.pagination.itemsPerPage"
      >
        <div slot="footer" class="pagination">
          <v-data-footer
            v-if="!isLoading && items.length > 5"
            :pagination="pagination"
            :options="pagination"
            :itemsPerPageOptions="rowsPerPageItems"
            @update:options="(value) => paginationHandler(value, true)"
            showFirstLastPage
            showCurrentPage
          >
            <template slot="page-text">
              {{ displayPagePagination() }}
            </template>
          </v-data-footer>
        </div>
        <div
          slot="item"
          slot-scope="props"
          class="content-item"
          :style="componentStyle"
        >
          <component
            :is="card"
            :showCheckbox="showCheckboxes"
            :showEditPen="showEditPen"
            @select="selectItem(props.item)"
            @click="onClick($event, props.item.id)"
            @edit="onEdit($event, props.item.id)"
            @delete="onDelete($event, props.item.id)"
            :isOwn="isOwn"
            v-bind="props.item"
            :display="display"
            :isSelected="isSelected(props.item.id)"
            :key="props.item.id"
            :loading="!!props.item.loading"
            :is-sla-expiring="isComplaintSLAExpiring(props.item.cplStateId)"
            :isExpanded="isExpanded(props.item.id)"
            :alreadyUsedNames="alreadyUsedNames"
            @expand="onExpandDetails"
          ></component>
        </div>
      </v-data-iterator>
    </div>

    <div v-if="isMobile" class="mobile-footer">
      <div class="mobile-footer-content">
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import DisplayListTypes from "@/models/displayListTypes.js";
import * as CplStates from "@/models/cplStates.js";
import { mapGetters } from "vuex";

export default {
  name: "DataGrid",
  props: {
    card: { type: Object, required: true },
    items: { type: Array, default: () => [] },
    showToolbar: { type: Boolean, default: true },
    showCheckboxes: { type: Boolean, default: true },
    showEditPen: { type: Boolean, default: true },
    display: { type: String, default: DisplayListTypes.CARDS },
    search: { type: String },
    isLoading: { type: Boolean, default: false },
    minimumCardWidth: { type: [Number, String], default: 0 },
    value: { type: Array, default: () => [] },
    isOwn: { type: Boolean, default: false },
    pagination: {
      type: Object,
      default: () => {
        return {
          page: 1,
          itemsPerPage: 12,
          itemsLength: 0,
        };
      },
    },
    rowsPerPageItems: {
      type: Array,
      default: () => [12, 24, 48, 96],
    },
    showDefaultGear: { type: Boolean, default: true },
    expandedComplaintIds: { type: Array, default: () => [] },
    alreadyUsedNames: { type: Array, default: () => [] },
  },
  mounted() {
    this.$nextTick(() => this.onResize());
  },
  data() {
    return {
      checkbox: true,
      displayListTypes: DisplayListTypes,
      cplStates: CplStates,
      cardWidth: undefined,
      minimumWidthToDisplayListMode: 1050,
      allowDisplayListMode: true,
    };
  },
  methods: {
    formatNumber(num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    displayPagePagination() {
      const paginationStart = this.formatNumber(this.pagination.pageStart + 1);
      const paginationStop = this.formatNumber(this.pagination.pageStop);
      const paginationCount = this.formatNumber(this.pagination.itemsLength);
      const paginationDefault = `${paginationStart} - ${paginationStop} z ${paginationCount}`;
      const paginationLastPage = `${paginationStart} - ${paginationCount} z ${paginationCount}`;
      const pageNumber =
        this.pagination.pageStart + this.pagination.itemsPerPage;
      if (pageNumber > this.pagination.itemsLength) {
        return paginationLastPage;
      } else {
        return paginationDefault;
      }
    },
    searchHandler() {
      const search = this.tempSearch;
      if (search === "" || search === null) {
        return;
      }
      this.tempSearch = search;
    },
    paginationHandler(pagination, scrollToTop) {
      if (
        this.pagination.page === pagination.page &&
        this.pagination.itemsPerPage === pagination.itemsPerPage
      ) {
        return;
      }
      if (scrollToTop) {
        window.scroll({ top: 0, behavior: "smooth" });
      }
      this.$emit("update:pagination", pagination);
    },
    selectItem(item) {
      const selected = [...this.value];
      item.error = "";
      const index = selected.findIndex((i) => i.id === item.id);
      if (index !== -1) {
        selected.splice(index, 1);
      } else {
        selected.push(item);
      }
      this.$emit("input", selected);
    },
    onResize() {
      if (+this.minimumCardWidth == 0) return;

      const table = this.$refs.table.$el;
      const tablewidth = table.offsetWidth;
      const maxCardsOnScreen = Math.floor(tablewidth / +this.minimumCardWidth);
      const factor = tablewidth / +this.minimumCardWidth / maxCardsOnScreen;
      const spaceWidth = (maxCardsOnScreen - 1) * 4;

      this.cardWidth = +this.minimumCardWidth * factor - spaceWidth;

      if (tablewidth < this.minimumWidthToDisplayListMode) {
        this.allowDisplayListMode = false;
        if (this.display === this.displayListTypes.INLINE) {
          this.$emit("update:display", this.displayListTypes.CARDS);
        }
      } else {
        this.allowDisplayListMode = true;
      }
    },
    onClick(type, prop) {
      this.$emit("click", { type, id: prop });
    },
    onEdit(model, id) {
      this.$emit("update:item", id, model);
    },
    onDelete(model, id) {
      this.$emit("delete", id);
    },
    isSelected(id) {
      return this.value.findIndex((i) => i.id === id) !== -1;
    },
    hasError(id) {
      const item = this.value.find((i) => i.id === id);
      return item ? item.error : "";
    },
    // Tymczasowe rozwiązanie, SLA jak na razie nie uwzględnia funkcji "zamrażania"
    isComplaintSLAExpiring(stateId) {
      return stateId ? stateId < this.cplStates.RESOLVED : false;
    },
    isExpanded(id) {
      return this.expandedComplaintIds.some((expandedId) => expandedId === id);
    },
    onExpandDetails(id) {
      this.$emit("expand", id);
    },
  },
  computed: {
    ...mapGetters("settings", ["isMobile"]),
    componentStyle() {
      if (this.isMobile) {
        return { "max-width": "100%", width: "100%", "flex-basis": "100%" };
      } else {
        return +this.minimumCardWidth != 0 &&
          this.display === DisplayListTypes.CARDS
          ? {
              "max-width": `${this.cardWidth}px`,
              "min-width": `${this.minimumCardWidth}px`,
            }
          : {};
      }
    },
    tmpSearch: {
      get() {
        return this.search;
      },
      set(newValue) {
        this.$emit("update:search", newValue);
      },
    },
    showGear() {
      if (!!this.showDefaultGear || this.showDefaultGear) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style scoped lang="scss">
.data-grid-container {
  .loader {
    position: sticky;
    top: 64px;
    z-index: 2;
    margin: -40px -12px 40px -12px;
    width: auto;
  }

  .data-grid {
    padding: 12px;

    &.is-loading {
      margin-top: -4px;
    }

    &.mobile {
      padding: 12px 0 0 0;

      .filter-container {
        ::v-deep.v-input.date-field {
          margin-left: 5px;
        }
      }

      .content-header {
        overflow: auto;
      }
    }

    &:not(.mobile) {
      .filter-container {
        ::v-deep.v-input.date-field {
          width: 192px; // to display full text in placeholder
          margin-left: 20px;
        }
      }
    }

    .text-searching {
      margin-top: -10px;
    }

    .header-buttons {
      margin-bottom: 15px;
    }

    .filter-container {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      min-height: 60px;
      align-items: center;

      .header {
        flex: 0 1 auto;
      }

      .text-searching {
        flex: 0 0 20%;
        margin-left: auto;
        padding: 0 0 0 10px;
      }

      ::v-deep.v-input.date-field {
        padding: 0;
      }
    }

    .content-header {
      display: flex;
      flex-direction: row;
      min-height: 64px;
      align-items: center;
      padding: 0 15px;
      background-color: $header-background;

      .header-actions {
        display: flex;
        margin-left: auto;

        @media only screen and (min-width: 900px) {
          flex-direction: row;
        }

        @media only screen and (max-width: 900px) {
          flex-direction: column;
        }
      }

      ::v-deep.selection-picker-wrapper {
        margin-right: auto;
      }
    }

    .content {
      padding-top: 10px;

      &.empty {
        text-align: center;
        font-size: 18px;
        margin-top: 20px;
      }

      &.cards:not(.empty) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .content-item {
          flex: 1 1;
          margin: 4px;
        }

        .pagination {
          flex-basis: 100%;
        }
      }

      &.inline {
        .content-item {
          margin-bottom: 10px;
        }
      }

      &.inlineDetails {
        .content-item {
          margin-bottom: 10px;
        }
      }
    }
  }

  &.mobile {
    display: flex;
    flex-direction: column;

    .data-grid {
      height: calc(100vh - 164px);
      overflow: auto;
    }

    .mobile-footer {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100vw;
      height: 80px;
      background-color: white;
      box-shadow: 0px 2px 4px 1px rgb(0 0 0 / 20%);

      .mobile-footer-content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
      }
    }
  }
}
</style>
